// src/components/Testimonials.jsx
import React, { useState } from 'react';
import './Testimonials.css';

const Testimonials = () => {
  const testimonials = [
    {
      quote: "Outstanding technical expertise and problem-solving abilities.",
      author: "John Smith",
      role: "CTO, Tech Innovations",
      company: "Tech Innovations",
      image: "👨‍💼",
      category: "AI Solutions"
    },
    {
      quote: "Delivered exceptional results consistently ahead of schedule.",
      author: "Sarah Johnson",
      role: "Project Lead",
      company: "DataCorp",
      image: "👩‍💼",
      category: "Data Engineering"
    },
    {
      quote: "Transformed our data pipeline with innovative ML solutions.",
      author: "Michael Chen",
      role: "Head of Engineering",
      company: "AI Dynamics",
      image: "👨‍💼",
      category: "Machine Learning"
    },
    {
      quote: "Exceptional ability to translate complex requirements into elegant solutions.",
      author: "Emma Davis",
      role: "Product Manager",
      company: "CloudTech Solutions",
      image: "👩‍💼",
      category: "Cloud Architecture"
    },
    {
      quote: "Outstanding contribution to our ML infrastructure modernization.",
      author: "David Wilson",
      role: "Director of AI",
      company: "InnovateAI",
      image: "👨‍💼",
      category: "AI Development"
    }
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const nextSlide = () => {
    setActiveIndex((prev) => (prev + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setActiveIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section id="testimonials" className="testimonials-section">
      <div className="container">
        <h2 className="section-title">Client Testimonials</h2>
        <div className="testimonials-container">
          <button className="nav-btn prev" onClick={prevSlide}>←</button>
          <div className="testimonials-wrapper">
            <div className="testimonial-card">
              <div className="testimonial-icon">{testimonials[activeIndex].image}</div>
              <blockquote>{testimonials[activeIndex].quote}</blockquote>
              <div className="testimonial-author">
                <h4>{testimonials[activeIndex].author}</h4>
                <p>{testimonials[activeIndex].role}</p>
                <p className="company">{testimonials[activeIndex].company}</p>
                <span className="category">{testimonials[activeIndex].category}</span>
              </div>
            </div>
          </div>
          <button className="nav-btn next" onClick={nextSlide}>→</button>
        </div>
        <div className="testimonial-indicators">
          {testimonials.map((_, index) => (
            <button
              key={index}
              className={`indicator ${index === activeIndex ? 'active' : ''}`}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;