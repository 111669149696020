// src/components/Contact.jsx
import React, { useState, useRef } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import './Contact.css';

const Contact = () => {
  const form = useRef();
  const [status, setStatus] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('sending');

    fetch('https://formspree.io/f/xkgnaoke', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: form.current.name.value,
        email: form.current.email.value,
        subject: form.current.subject.value,
        message: form.current.message.value
      })
    })
    .then(response => {
      if (response.ok) {
        setStatus('sent');
        toast.success('Message sent successfully!');
        form.current.reset();
        setTimeout(() => setStatus(''), 3000);
      } else {
        setStatus('error');
        toast.error('Failed to send message. Please try again.');
      }
    })
    .catch(error => {
      setStatus('error');
      toast.error('Failed to send message. Please try again.');
      console.error('Error:', error);
    });
  };

  return (
    <section id="contact" className="contact-section">
      <div className="container">
        <h2 className="section-title">Get In Touch</h2>
        <p className="contact-description">
          Have a question or want to work together?
        </p>
        
        <form ref={form} className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="Your name"
              required
              className="form-control"
            />
            <span className="focus-border"></span>
          </div>

          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="your@email.com"
              required
              pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
              className="form-control"
            />
            <span className="focus-border"></span>
          </div>

          <div className="form-group">
            <input
              type="text"
              name="subject"
              placeholder="What's this about?"
              required
              className="form-control"
            />
            <span className="focus-border"></span>
          </div>

          <div className="form-group">
            <textarea
              name="message"
              placeholder="Your message here..."
              required
              className="form-control"
            />
            <span className="focus-border"></span>
          </div>

          <button 
            type="submit" 
            className={`submit-btn ${status}`}
            disabled={status === 'sending'}
          >
            {status === 'sending' ? (
              <span className="loading-spinner"></span>
            ) : status === 'sent' ? (
              'Message Sent!'
            ) : (
              'Send Message'
            )}
          </button>
        </form>
      </div>
      <Toaster position="bottom-right" />
    </section>
  );
};

export default Contact;
