// src/components/Footer.jsx
import React from 'react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Chand Rayee</h3>
            <p>AI/ML Engineer & Full Stack Developer</p>
            <p>Specializing in AI Solutions, Web Development, and Data Engineering</p>
          </div>
          
          <div className="footer-section">
            <h3>Contact</h3>
            <p>Email: chandrayee.cse@gmail.com</p>
            <p>Phone: +91 9334575742</p>
            <p>Location: India</p>
          </div>
          
          <div className="footer-section">
            <h3>Connect</h3>
            <div className="social-links">
              <a href="https://github.com/mrchandrayee" target="_blank" rel="noopener noreferrer">GitHub</a>
              <a href="https://www.linkedin.com/in/mrchandrayee" target="_blank" rel="noopener noreferrer">LinkedIn</a>
              <a href="https://www.kaggle.com/mrchandrayee" target="_blank" rel="noopener noreferrer">Kaggle</a>
            </div>
          </div>
        </div>
        
        <div className="footer-bottom">
          <p>&copy; {currentYear} Chand Rayee. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;