// src/components/Skills.jsx
import React from 'react';
import './Skills.css';

const Skills = () => {
  const skillCategories = [
    {
      title: "AI/ML Engineering",
      skills: [
        "Machine Learning & Deep Learning",
        "TensorFlow, PyTorch, Scikit-learn",
        "Neural Networks & Deep Learning",
        "Computer Vision & NLP",
        "Model Training & Optimization",
        "Machine Learning Pipelines"
      ],
      icon: "🧠"
    },
    {
      title: "Full Stack Development",
      skills: [
        "Frontend: React.js, Next.js, Vue.js",
        "Backend: Django, Flask, Node.js",
        "Database: PostgreSQL, MongoDB",
        "RESTful APIs & GraphQL",
        "Modern CSS & Tailwind",
        "Responsive Web Design"
      ],
      icon: "💻"
    },
    {
      title: "Data Engineering",
      skills: [
        "ETL Pipeline Development",
        "Data Warehouse Solutions",
        "Apache Airflow, Spark",
        "Real-time Data Processing",
        "Data Quality Management",
        "Database Optimization"
      ],
      icon: "🔄"
    },
    {
      title: "DevOps & Cloud",
      skills: [
        "Docker & Kubernetes",
        "CI/CD (Jenkins, GitHub Actions)",
        "AWS, GCP, Heroku",
        "Infrastructure as Code",
        "Monitoring & Logging",
        "Security Best Practices"
      ],
      icon: "☁️"
    },
    {
      title: "Analytics & BI",
      skills: [
        "Business Intelligence Tools",
        "Data Visualization",
        "Statistical Analysis",
        "Dashboard Development",
        "Reporting Automation",
        "Performance Metrics"
      ],
      icon: "📊"
    },
    {
      title: "Development Tools",
      skills: [
        "Version Control (Git)",
        "Testing (PyTest, Jest)",
        "API Development",
        "Code Quality Tools",
        "Documentation",
        "Agile Methodologies"
      ],
      icon: "🛠️"
    },  // Added comma here
    {
      title: "Core Programming",
      skills: [
        "Object-Oriented Programming",
        "Data Structures & Algorithms",
        "Design Patterns",
        "System Architecture",
        "Performance Optimization",
        "Clean Code Practices"
      ],
      icon: "⚡"
    },
    {
      title: "Mobile Development",
      skills: [
        "React Native Development",
        "Cross-Platform Solutions",
        "Mobile UI/UX Design",
        "App State Management",
        "Native APIs Integration",
        "Mobile App Performance"
      ],
      icon: "📱"
    }
  ];

  return (
    <section id="skills" className="skills-section">
      <div className="container">
        <h2 className="section-title">Technical Expertise</h2>
        <p className="section-description">
          Comprehensive skillset in AI, Web Development, and Data Engineering
        </p>
        <div className="skills-grid">
          {skillCategories.map((category, index) => (
            <div className="skill-card" key={index}>
              <div className="skill-icon">{category.icon}</div>
              <h3>{category.title}</h3>
              <ul>
                {category.skills.map((skill, skillIndex) => (
                  <li key={skillIndex}>{skill}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;