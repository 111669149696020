// src/components/About.jsx
import React from 'react';
import './About.css';

const About = () => {
  return (
    <section id="about" className="about-section">
      <div className="about-background">
        <div className="gradient-orb"></div>
        <div className="gradient-orb-2"></div>
      </div>
      <div className="container">
        <h2 className="section-title">About Me</h2>
        <div className="about-content">
          <div className="about-grid">
            <div className="about-image-container">
              <div className="about-image-frame">
                <div className="image-placeholder">CR</div>
              </div>
            </div>
            <div className="about-text">
              <p className="intro">
                Hi, I'm a software engineer specializing in full-stack development, AI/ML, 
                and cloud architecture. With over 5 years of experience, I focus on 
                building scalable and innovative solutions.
              </p>
              <div className="tech-stack">
                <span className="tech-tag">Python</span>
                <span className="tech-tag">React</span>
                <span className="tech-tag">Django</span>
                <span className="tech-tag">AI/ML</span>
              </div>
            </div>
          </div>
          
          <div className="highlights">
            {[
              { number: '5+', label: 'Years Experience' },
              { number: '50+', label: 'Projects Completed' },
              { number: '20+', label: 'Happy Clients' }
            ].map((item, index) => (
              <div className="highlight-card" key={index}>
                <span className="highlight-number">{item.number}</span>
                <span className="highlight-label">{item.label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;