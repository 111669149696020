// src/components/Hero.jsx
import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section id="hero" className="hero">
      <div className="hero-background">
        <div className="gradient-sphere"></div>
        <div className="gradient-sphere-2"></div>
      </div>
      
      <div className="hero-content animate-fade-in">
        <div className="title">
          <span className="greeting animate-slide-down">Hi, I'm</span>
          <span className="name animate-slide-up">Chand Rayee</span>
          <span className="role animate-fade-in">AI/ML Engineer & Full Stack Developer</span>
        </div>
        
        <p className="description animate-fade-in">
          A passionate developer specializing in Python, Django, JavaScript, and React.
          Focused on AI, Machine Learning, and Data Science solutions.
        </p>

        <div className="cta-buttons animate-slide-up">
          <button className="primary-btn">View Projects</button>
          <button className="secondary-btn">Contact Me</button>
        </div>
      </div>
    </section>
  );
};

export default Hero;