// src/components/Projects.jsx
import React from 'react';
import './Projects.css';

const Projects = () => {
  const projects = [
    {
      title: "AI Chatbot Platform",
      description: "Advanced chatbot using NLP and machine learning for customer support automation.",
      technologies: ["TensorFlow", "Python", "Node.js", "MongoDB"],
      impact: "Reduced response time by 75%, handling 5000+ queries daily",
      icon: "🤖",
      category: "AI/ML"
    },
    {
      title: "E-commerce Solution",
      description: "Full-stack e-commerce platform with real-time inventory management.",
      technologies: ["React", "Node.js", "PostgreSQL", "Redis"],
      impact: "Increased sales by 150% in first quarter",
      icon: "🛍️",
      category: "Full Stack"
    },
    {
      title: "Data Analytics Dashboard",
      description: "Real-time analytics platform for business intelligence.",
      technologies: ["Vue.js", "D3.js", "Python", "AWS"],
      impact: "Improved decision-making efficiency by 60%",
      icon: "📊",
      category: "Data Analytics"
    },
    {
      title: "ML Model Pipeline",
      description: "Automated machine learning pipeline for model training and deployment.",
      technologies: ["PyTorch", "MLflow", "Docker", "Kubernetes"],
      impact: "Reduced model deployment time by 80%",
      icon: "🔄",
      category: "AI/ML"
    },
    {
      title: "Real-time Data Processing",
      description: "Scalable ETL pipeline for processing large-scale data streams.",
      technologies: ["Apache Spark", "Kafka", "Python", "AWS"],
      impact: "Processing 1M+ events per minute",
      icon: "⚡",
      category: "Data Engineering"
    },
    {
      title: "Mobile App Platform",
      description: "Cross-platform mobile application for social networking.",
      technologies: ["React Native", "Node.js", "GraphQL", "Firebase"],
      impact: "100K+ active users",
      icon: "📱",
      category: "Mobile"
    }
  ];

  return (
    <section id="projects" className="projects-section">
      <div className="container">
        <h2 className="section-title">Featured Projects</h2>
        <div className="project-filters">
          {["All", "AI/ML", "Full Stack", "Data Engineering", "Mobile"].map(filter => (
            <button key={filter} className="filter-btn">{filter}</button>
          ))}
        </div>
        <div className="projects-grid">
          {projects.map((project, index) => (
            <div className="project-card" key={index}>
              <div className="project-header">
                <div className="project-icon">{project.icon}</div>
                <span className="project-category">{project.category}</span>
              </div>
              <h3>{project.title}</h3>
              <p className="project-description">{project.description}</p>
              <div className="tech-stack">
                {project.technologies.map((tech, techIndex) => (
                  <span key={techIndex} className="tech-tag">{tech}</span>
                ))}
              </div>
              <p className="project-impact">{project.impact}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;