// src/components/Header.jsx
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('hero');

  const navLinks = useMemo(() => [
    { href: '#hero', text: 'Home' },
    { href: '#about', text: 'About' },
    { href: '#skills', text: 'Skills' },
    { href: '#services', text: 'Services' },
    { href: '#projects', text: 'Projects' },
    { href: '#contact', text: 'Contact' }
  ], []);

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY + 100;
    const currentSection = navLinks.find(({ href }) => {
      const element = document.getElementById(href.substring(1));
      if (element) {
        const { offsetTop, offsetHeight } = element;
        return scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight;
      }
      return false;
    });

    if (currentSection) {
      setActiveSection(currentSection.href.substring(1));
    }
  }, [navLinks]);

  const scrollToSection = useCallback((elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      const headerOffset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }, []);

  const handleNavClick = useCallback((e) => {
    e.preventDefault();
    const sectionId = e.currentTarget.getAttribute('href').substring(1);
    scrollToSection(sectionId);
    setIsMenuOpen(false);
    setActiveSection(sectionId);
  }, [scrollToSection]);

  // Handle scroll event
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header className="header">
      <nav className="nav-container">
        <div className="logo">
          <a 
            href="#hero" 
            onClick={handleNavClick}
            className={activeSection === 'hero' ? 'active' : ''}
          >
            CR
          </a>
        </div>
        
        <button 
          className={`mobile-menu ${isMenuOpen ? 'active' : ''}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Toggle menu"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
          {navLinks.map(({ href, text }) => (
            <li key={href}>
              <a 
                href={href}
                onClick={handleNavClick}
                className={activeSection === href.substring(1) ? 'active' : ''}
              >
                {text}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;