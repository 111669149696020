// src/components/Services.jsx
import React from 'react';
import './Services.css';

const Services = () => {
  const serviceCategories = [
    {
      title: "AI/ML Solutions",
      icon: "🤖",
      items: [
        "Custom AI Model Development",
        "Deep Learning Solutions",
        "Natural Language Processing",
        "Computer Vision Systems",
        "Model Optimization",
        "Cloud Deployment"
      ]
    },
    {
      title: "Full Stack Development",
      icon: "💻",
      items: [
        "Custom Web Solutions",
        "E-commerce Platforms",
        "Content Management Systems",
        "Progressive Web Apps",
        "API Development",
        "Database Design"
      ]
    },
    {
      title: "Data Engineering",
      icon: "📊",
      items: [
        "Data Pipeline Development",
        "ETL Process Design",
        "Data Warehouse Solutions",
        "Real-time Processing",
        "Data Quality Management",
        "Analytics Solutions"
      ]
    }
  ];

  return (
    <section id="services" className="services-section">
      <div className="container">
        <h2 className="section-title">Services</h2>
        <div className="services-grid">
          {serviceCategories.map((category, index) => (
            <div className="service-card" key={index}>
              <div className="service-icon">{category.icon}</div>
              <h3>{category.title}</h3>
              <ul>
                {category.items.map((item, itemIndex) => (
                  <li key={itemIndex}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;